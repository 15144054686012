.color-picker-wrapper {
  padding: 20px;
  box-sizing: content-box;
}

.color-picker-basic-color {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 0;
  padding: 0;
}

.color-picker-basic-color button {
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 16px;
  width: 16px;
  cursor: pointer;
  list-style-type: none;
}

.color-picker-basic-color button.active {
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.color-picker-saturation {
  width: 100%;
  position: relative;
  margin-top: 15px;
  height: 150px;
  background-image: linear-gradient(transparent, black),
    linear-gradient(to right, white, transparent);
  user-select: none;
}
.color-picker-saturation_cursor {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  box-shadow: 0 0 15px #00000026;
  box-sizing: border-box;
  transform: translate(-10px, -10px);
}
.color-picker-hue {
  width: 100%;
  position: relative;
  margin-top: 15px;
  height: 12px;
  background-image: linear-gradient(
    to right,
    rgb(255, 0, 0),
    rgb(255, 255, 0),
    rgb(0, 255, 0),
    rgb(0, 255, 255),
    rgb(0, 0, 255),
    rgb(255, 0, 255),
    rgb(255, 0, 0)
  );
  user-select: none;
  border-radius: 12px;
}

.color-picker-hue_cursor {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  box-shadow: #0003 0 0 0 0.5px;
  box-sizing: border-box;
  transform: translate(-10px, -4px);
}

.color-picker-color {
  border: 1px solid #ccc;
  margin-top: 15px;
  width: 100%;
  height: 20px;
}
