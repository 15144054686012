@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
  :root {
    --color-1: #343a62;
    --color-2: #7b82b9;
    --color-3: #a1a7cd;
    --color-5: #3a86ff;
    --color-6: #ec308b;
    --color-8: #eff1f8;
    --color-10: #e1e3ef;
    --color-13: #eeeff6;
    --color-14: #232120;

    --border-1: #f9c0dc;
    --border-2: #eeeff6;
    --border-3: #e1e3ef;
    --border-4: #7b82b9;
    --border-5: #3a86ff;
    --border-6: #c3daff;
    --border-7: #ec308b;
    --border-8: #ffa432;
    --border-9: #21c7c0;
    --border-10: #a1ece9;
    --border-12: #ffc884;
    --border-13: #d9e0ff;
    --border-14: #ffcde5;
    --border-16: #a1a7cd;
    --border-18: #a382ff;
    --border-19: #343a62;
    --border-20: #3a86ff;
    --border-21: #f4f4f4;
    --border-22: #ecf3ff;
    --border-23: #e5e7eb;

    --surface-1: #f6f7fa;
    --surface-2: #ffffff;
    --surface-3: #eeeff6;
    --surface-4: #fdebf4;
    --surface-5: #7b82b9;
    --surface-6: #c3daff;
    --surface-7: #3a86ff;
    --surface-8: #ecf3ff;
    --surface-9: #343a62;
    --surface-10: #21c7c0;
    --surface-12: #ec308b;
    --surface-13: #ffa432;
    --surface-14: #ffedd6;
    --surface-15: #e090b87d;
    --surface-16: #a1a7cd;
    --surface-17: #e1e3ef;
    --surface-18: #e8f9f8;
    --surface-19: #0c87704d;
    --surface-28: #ff2a67;
    --surface-29: #a382ff;
    --surface-30: #66c8dc;
    --surface-31: #ffbf6f;
    --surface-32: #ffc884;
    --surface-34: #fff4d6;
    --surface-35: #e9eef8;
    --surface-36: #faebe8;
    --surface-37: #eaf3ff;
    --surface-38: #e6f3ec;
    --surface-39: #fff7ed;
    --surface-40: #343a6299;
    --surface-41: #525659;
    --surface-42: #f6f2ff;
    --surface-43: #f0fafc;
    --surface-44: #ec308b1a;
    --surface-45: #a382ff1a;
    --surface-46: #21c7c01a;
    --surface-47: #3a86ff1a;
    --surface-48: #ffa4321a;
    --surface-49: #7b82b91a;
    --surface-50: #fef2f5;
    --surface-51: #f3f7ff;
    --surface-52: #fff6ea;
    --surface-53: #efeaff;
    --surface-54: #fbeeed;
    --surface-55: #59dba7;
    --surface-56: #e16a78;
    --surface-57: #8191e8;

    --label-1: #ecf3ff;
    --label-2: #fdebf4;
    --label-3: #21c7c0;
    --label-4: #eeeff6;
    --label-5: #e8f9f8;

    --button-1: #3a86ff;
    --button-2: #c3daff;
    --button-3: #e1e3ef;
    --button-4: #ec308b;
    --button-5: #21c7c0;
    --button-6: #ffcde5;

    --hover-1: #eeeff6;
    --hover-2: #f6f7fa;
    --hover-3: #e1e3ef;

    --dashboard-card-1: #ffffff;

    --text-1: #3a86ff;
    --text-2: #7b82b9;
    --text-3: #343a62;
    --text-4: #a1a7cd;
    --text-5: #ffffff;
    --text-6: #ffa432;
    --text-7: #ec308b;
    --text-8: #21c7c0;
    --text-9: #abafb7;
    --text-14: #ff2a67;
    --text-15: #a382ff;
    --text-16: #66c8dc;
    --text-17: #ffbf6f;
    --text-18: #373a40;
    --text-19: #79c689;
    --text-20: #e16a78;
    --text-21: #8191e8;

    --input-1: #ffffff;
    --input-2: #f6f7fa;

    --ring-1: #c3daff;
    --ring-2: #c3daff;
  }

  /* @media (prefers-color-scheme: dark) {
    :root {
      --color-1: #fff;
      --color-8: #161b22;
      --color-10: #161b22;
      --color-14: #bebebe;

      --text-1: #fff;
      --text-2: #8d96a0;
      --text-3: #b2bac6;
      --text-6: #ab6d22;
      --text-7: #ad2466;

      --border-2: #161b22;
      --border-3: #2d3847;
      --border-6: #4c5665;
      --border-12: #4d3c27;
      --border-13: #5c6177;
      --border-14: #614e57;
      --border-16: #505366;
      --border-21: #161b22;
      --border-23: #161b22;

      --label-1: #515458;
      --label-2: #403a3d;
      --label-3: #0b413e;
      --label-4: #4c4c4f;
      --label-5: #474c4c;

      --surface-1: #0d1117;
      --surface-2: #161b22;
      --surface-3: #30363d;
      --surface-4: #31081d;
      --surface-7: #21509b;
      --surface-8: #0d1117;
      --surface-9: #5f69b1;
      --surface-12: #ad2466;
      --surface-13: #ab6d22;
      --surface-14: #585149;
      --surface-16: #a1a7cd;
      --surface-17: #0d1117;
      --surface-18: #353a39;
      --surface-39: #403d3a;
      --surface-42: #424145;
      --surface-43: #404344;

      --hover-1: #5a5a5e;
      --hover-2: #68686a;
      --hover-3: #71737a;

      --button-2: #515b6b;
      --button-3: #56575c;

      --dashboard-card-1: #30363d;

      --ring-1: #586476;
      --ring-2: #586476;
    }
  } */
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .message-button-loader {
    background: linear-gradient(#eeb4ce, #eeb4ce 50%, #ec308b 50%, #ec308b);
    background-size: 100% 200%;
    animation: 10s linear forwards message-button-loader-kf;
  }
}

body {
  background-color: var(--surface-1);
  color: var(--color-1);

  /* @media (prefers-color-scheme: dark) {
    .shadow {
      --tw-shadow: 0 1px 3px 0 #000, 0 1px 2px -1px #fff;
      --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
        0 1px 2px -1px var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }

    .focus\:ring-2:focus {
      --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
        var(--tw-ring-offset-width) #586476;
      --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
        calc(2px + var(--tw-ring-offset-width)) #586476;
      box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
        var(--tw-shadow, 0 0 #0000);
    }
  } */
}

* {
  border-color: var(--border-23);
}
