html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
}

html > body {
  font-size: 12px;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html > body > div#__next {
  height: 100%;
}

/* React Calendar */
div.react-calendar {
  display: flex;
  flex-direction: column;
  width: 11rem;
  border-radius: 0.375rem;
}

div.react-calendar__navigation {
  color: #fff;
  height: 2rem;
  text-align: center;
  background-color: #343a62;
  border-radius: 0.375rem 0.375rem 0 0;
}

button.react-calendar__navigation__label {
  font-weight: bold;
  height: 2rem;
  padding-right: 0.7rem;
  padding-left: 0.7rem;
  text-transform: capitalize;
}

div.react-calendar__viewContainer {
  background-color: #fff;
  border-radius: 0 0 0.375rem 0.375rem;
  padding: 6px 4px;
}

button.react-calendar__tile {
  margin-top: 6px;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  outline: none;
  background-color: #fff;
}

button.react-calendar__tile:disabled {
  color: #a1a7cd;
}

button.react-calendar__tile--rangeBothEnds {
  border-radius: 6px;
  background-color: #3a86ff;
  color: #fff;
  height: 24px;
}

div.react-calendar__month-view__weekdays__weekday {
  text-align: center;
  font-weight: 500;
}

div.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}

button.react-calendar__month-view__days__day--neighboringMonth {
  color: #a1a7cd;
}

/* React Cropper */
.cropper-crop-box,
.cropper-view-box {
  border-radius: 50%;
}

.cropper-view-box {
  box-shadow: 0 0 0 1px #39f;
  outline: 0;
}

.cropper-face {
  background-color: inherit !important;
}

.cropper-dashed,
.cropper-point.point-se,
.cropper-point.point-sw,
.cropper-point.point-nw,
.cropper-point.point-ne,
.cropper-line {
  display: none !important;
}

.cropper-view-box {
  outline: inherit !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

div.rendered-html {
  white-space: normal;
}

div.rendered-html ul {
  list-style-position: inside;
  list-style-type: disc;
}

div.rendered-html ol {
  list-style-position: inside;
  list-style-type: decimal;
}

div.rendered-html a {
  color: #3a86ff;
  text-decoration: underline;
}

/* react-activity-calendar */
.react-activity-calendar__legend-weekday > text,
.react-activity-calendar__legend-month > text {
  font-weight: bold;
  font-size: 0.625rem;
  letter-spacing: 0.2px;
  color: #7b82b9;
}

.react-activity-calendar > svg > g > rect {
  stroke: none;
}

/* Do not display week-end */
.react-activity-calendar > svg > g > rect:nth-child(6),
.react-activity-calendar > svg > g > rect:nth-child(7),
.react-activity-calendar
  > svg
  > g:not(:last-child)
  > rect:nth-last-child(-n + 2) {
  display: none;
}

iframe {
  height: 100vh;
}

.no-arrows::-webkit-inner-spin-button,
.no-arrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-arrows {
  -moz-appearance: textfield;
}

.flat-breaks br {
  content: '';
}

.flat-breaks br:after {
  content: ' ';
}

.flat-breaks p {
  display: inline;
}

/* recharts */
.recharts-layer.recharts-pie,
.recharts-sector:focus {
  outline: none !important;
  outline-color: none;
  outline-style: none;
  outline-width: none;
}

/* emoji-picker-react */
.EmojiPickerReact {
  --epr-category-navigation-button-size: 1.25rem !important;
  --epr-category-label-height: 1.5rem !important;
  --epr-emoji-size: 1.25rem !important;
}

input[type='time']::-webkit-inner-spin-button,
input[type='time']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

/* docx-preview */
section.docx {
  width: 100% !important;
  padding: 50px !important;
}

section.docx > article > table {
  width: 100% !important;
}
